import { ALL_ROLES } from "../../../../../shared/rules/interface/EnumRights";
import userStore from "../../../../../store/user.store";

export const checkVerifiedAndStatus = (isVerifiedByGov: boolean, status: string) => {
  const exception = status === "Найденный";
  const accessStatuses = ["Запланирован", "Не согласовано", "В график", "На доработку"];
  const checkUserSearchManager =
    userStore.user?.role === (ALL_ROLES.SEARCH_MANAGER || ALL_ROLES.SEARCH_HEAD_MANAGER);

  if (checkUserSearchManager) {
    return checkUserSearchManager;
  }

  if (!isVerifiedByGov && accessStatuses.includes(status)) {
    return true;
  }

  if (!isVerifiedByGov && exception) {
    return false;
  }

  if (isVerifiedByGov && !exception) {
    return true;
  }

  return true;
};
