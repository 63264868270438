import { Box, CircularProgress, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { AxiosError } from "axios";
import dayjs, { Dayjs } from "dayjs";
import { FC, useEffect, useState } from "react";
import { MotivationAllTables } from "../../../../../../pages/DashboardPage/pages/MotivationPage/ui/MotivationAllTables/MotivationAllTables";
import { SearchMontDatePicker } from "../../../../../../pages/DashboardPage/pages/MotivationPage/ui/SearchMontDatePicker/SearchMontDatePicker";
import {
  IMotivationCoefsRequest,
  IMotivationReleaseRequest,
} from "../../../../../../services/motivation/IMotivationRequest";
import {
  IMotivationCalcAll,
  IMotivationCoefsSizeAudienctResponse,
  IMotivationRoiRow,
} from "../../../../../../services/motivation/IMotivationResponse";
import MotivationService from "../../../../../../services/motivation/motivation.service";
import { AsideCardLayout } from "../../../../../AsideCardLayout/AsideCardLayout";
import { CustomButton } from "../../../../../UI/Buttons/CustomButton/CustomButton";
import { IUserReleaseTables } from "./IUserReleaseTables";

export const UserReleaseTables: FC<IUserReleaseTables> = (props) => {
  const { userId } = props;

  const [date, setDate] = useState<Dayjs | null>(dayjs());

  const [coefs, setCoefs] = useState<IMotivationRoiRow[] | null>(null);
  const [sizeAudience, setSizeAudience] = useState<null | IMotivationCoefsSizeAudienctResponse[]>(
    null
  );

  const [isLoading, setLoading] = useState(false);

  const [releaseMotivationByUser, setReleaseMotivationByUser] = useState<IMotivationCalcAll | null>(
    null
  );

  const [isLoadingByUser, setLoadingByUser] = useState(false);

  const getMotivationCoef = () => {
    if (date) {
      setLoading(true);

      const option: IMotivationCoefsRequest = {
        month: date?.month() + 1,
        userId,
        year: date?.year(),
      };
      MotivationService.getNewMotivationCoefs(option)
        .then((res) => {
          setCoefs(res.data.roiRows);
          setSizeAudience(res.data.sizeAudience);
          setLoading(false);
        })
        .catch((err) => {
          if (err instanceof AxiosError) {
            setLoading(false);
          }
        });
    }
  };

  const getReleaseMotivationByUser = (options: IMotivationReleaseRequest) => {
    setLoadingByUser(true);

    const option: IMotivationReleaseRequest = {
      month: options.month,
      userId: options.userId,
      year: options.year,
    };
    MotivationService.motivationCalcAll(option)
      .then((res) => {
        setReleaseMotivationByUser(res.data);
        setLoadingByUser(false);
      })
      .catch((err) => {
        if (err instanceof AxiosError) {
          setLoadingByUser(false);
        }
      });
  };

  const changeDateReleaseTables = () => {
    if (date && date.isValid()) {
      getMotivationCoef();
      getReleaseMotivationByUser({ month: date?.month() + 1, userId, year: date?.year() });
    }
  };

  const sortHandler = (type: string, sortType: "asc" | "desc") => {
    if (
      type === "integration_date" &&
      releaseMotivationByUser &&
      releaseMotivationByUser.forRelease
    ) {
      const sortedReleases = [...releaseMotivationByUser.forRelease.motivationReleases].sort(
        (a, b) => {
          const dateA = new Date(a.integration?.integration_date ?? "");
          const dateB = new Date(b.integration?.integration_date ?? "");

          const timeA = dateA.getTime();
          const timeB = dateB.getTime();

          if (sortType === "asc") {
            return timeA - timeB;
          } else {
            return timeB - timeA;
          }
        }
      );

      const updatedReleaseMotivationByUser = {
        ...releaseMotivationByUser,
        forRelease: {
          ...releaseMotivationByUser.forRelease,
          motivationReleases: sortedReleases,
        },
      };
      setReleaseMotivationByUser(updatedReleaseMotivationByUser);
    }
  };

  useEffect(() => {
    getMotivationCoef();

    if (date && date.isValid()) {
      getReleaseMotivationByUser({ month: date?.month() + 1, userId, year: date?.year() });
    }
  }, []);

  return (
    <Box
      sx={{
        maxHeight: "100%",
        overflow: "auto",
        pt: 2,
      }}>
      <AsideCardLayout>
        <Stack direction={"row"} justifyContent={"start"} pb={2} pt={2}>
          <Stack direction={"row"} alignItems={"center"} spacing={1}>
            <SearchMontDatePicker initValue={date} onChange={setDate} />
            <Box display={"flex"} height={"36px"}>
              <CustomButton
                variant="contained"
                onClick={changeDateReleaseTables}
                disabled={isNaN(date?.month()!) && isNaN(date?.year()!)}>
                применить
              </CustomButton>
            </Box>
          </Stack>
        </Stack>
        {!isLoadingByUser && releaseMotivationByUser ? (
          <MotivationAllTables
            hideUrlSortCode
            sortHandler={sortHandler}
            data={releaseMotivationByUser}
            isShowTableReleaseCoefs
            dataReleaseCoef={coefs || undefined}
            userId={userId}
            readOnlyRelease
            isShowChannelMotivation={false}
            readOnlyMotivationUser
            talbeHeaderColor="#f2f3f5"
            readOnlyChannelPrice={true}
            readOnlyPercentChannels
            motivationType="release"
            managerId={userId}
            showDeleteIconRoiTable={false}
            showAddedSelectRoiTable={false}
            month={date ? date?.month() + 1 : new Date().getMonth() + 1}
            year={date?.year() || new Date().getFullYear()}
            sizeAudience={sizeAudience !== null && sizeAudience?.length > 0 ? sizeAudience : null}
          />
        ) : (
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"center"}
            spacing={1}
            sx={{ mt: 2 }}>
            <Typography variant="h5">Идёт загрузка таблицы...</Typography>
            <CircularProgress sx={{ minHeight: 25, maxHeight: 25, minWidth: 25, maxWidth: 25 }} />
          </Stack>
        )}
      </AsideCardLayout>
    </Box>
  );
};
