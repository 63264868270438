import { FC } from "react";
import { IMotivationAllTables } from "./IMotivationAllTables";
import { MotivationReleaseTable } from "../MotivationReleaseTable/MotivationReleaseTable";
import { AccordionWrapper } from "../../../../../../components/AccordionWrapper/AccordionWrapper";
import { MotivationGoodsCategoryTable } from "../MotivationGoodsCategoryTable/MotivationGoodsCategoryTable";
import { SearchMotivationTable } from "../SearchMotivationTable/SearchMotivationTable";
import { MotivationRoiTable } from "../MotivationRoiTable/MotivationRoiTable";
import { SearchChannelPriceTable } from "../SearchChannelPriceTable/SearchChannelPriceTable";
import MotivationTable from "../../../../../../components/MotivationTableSection/ui/MotivationTable/MotivationTable";
import { MotivationKpiPercentChannel } from "../MotivationKpiPercentChannel/MotivationKpiPercentChannel";
import { priceTransormFormat } from "../../../../../../shared/functions/functions";
import { GuaranteeTable } from "../GuaranteeTable/GuaranteeTable";

export const MotivationAllTables: FC<IMotivationAllTables> = (props) => {
  const {
    data,
    isShowTableReleaseCoefs = false,
    isShowTableSearchChannelPrice = false,
    userId,
    dataReleaseCoef,
    dataSearchChannel,
    isShowChannelMotivation,
    readOnlyRelease = false,
    isAccordOpenTableChannelPrice = false,
    isAccordOpenTableRelease = false,
    readOnlyMotivationUser,
    talbeHeaderColor = "#f2f3f5",
    readOnlyChannelPrice = false,
    dataSearchPercentChannels,
    readOnlyPercentChannels = false,
    motivationType,
    managerId,
    showDeleteIconRoiTable,
    showAddedSelectRoiTable,
    roiAddedSelectMonth,
    roiAddedSelectUserId,
    roiAddedSelectYear,
    onChangeMotivationByUserCell,
    month,
    year,
    sizeAudience,
    sortHandler,
    hideUrlSortCode,
  } = props;

  return (
    <>
      {isShowTableReleaseCoefs && dataReleaseCoef !== undefined && dataReleaseCoef !== null && (
        <MotivationRoiTable
          isInitShowTable={isAccordOpenTableRelease}
          tableItems={dataReleaseCoef}
          readOnly={readOnlyRelease}
          talbeHeaderColor={talbeHeaderColor}
          showDeleteIcon={showDeleteIconRoiTable}
          showAddedSelect={showAddedSelectRoiTable}
          month={roiAddedSelectMonth}
          userId={roiAddedSelectUserId}
          year={roiAddedSelectYear}
          sizeAudience={sizeAudience}
        />
      )}
      {isShowTableSearchChannelPrice &&
        dataSearchChannel !== undefined &&
        dataSearchChannel !== null && (
          <AccordionWrapper
            title="Показатели для расчета KPI за найденные каналы"
            isOpen={isAccordOpenTableChannelPrice}>
            <SearchChannelPriceTable
              data={dataSearchChannel}
              maxWidth="100%"
              talbeHeaderColor={talbeHeaderColor}
              readOnly={readOnlyChannelPrice}
            />
          </AccordionWrapper>
        )}
      {dataSearchPercentChannels && (
        <MotivationKpiPercentChannel
          data={dataSearchPercentChannels}
          readOnly={readOnlyPercentChannels}
          talbeHeaderColor={talbeHeaderColor}
        />
      )}
      {data.forRelease?.guaranteeNotCompleted && (
        <GuaranteeTable
          talbeHeaderColor={talbeHeaderColor}
          guaranteeNotCompleted={data.forRelease.guaranteeNotCompleted}
        />
      )}
      {data && userId !== 0 && (
        <MotivationTable
          summaryTableData={data}
          userId={userId?.toString()}
          readOnly={readOnlyMotivationUser}
          talbeHeaderColor={talbeHeaderColor}
          onChangeCell={onChangeMotivationByUserCell}
        />
      )}
      {data?.forRelease !== null && data?.forRelease !== undefined && (
        <MotivationReleaseTable
          hideUrlSortCode={hideUrlSortCode}
          sortHandler={sortHandler}
          motivationReleases={data.forRelease?.motivationReleases}
          motivationSumm={data.forRelease?.motivationSumm}
          sxProps={{ maxHeight: "calc(100vh - 170px)" }}
          talbeHeaderColor={talbeHeaderColor}
          motivationType={motivationType}
          managerId={managerId}
        />
      )}
      {data?.forReleaseByGoodsCategory !== null &&
        data?.forReleaseByGoodsCategory !== undefined && (
          <AccordionWrapper title="Детализация KPI Таблица за выпущенные интеграции по товарным категориям">
            <MotivationGoodsCategoryTable
              forReleaseByGoodsCategory={data?.forReleaseByGoodsCategory}
              talbeHeaderColor={talbeHeaderColor}
            />
          </AccordionWrapper>
        )}
      {data?.forSearch !== null && data?.forSearch !== undefined && isShowChannelMotivation && (
        <AccordionWrapper
          title="Таблица найденных каналов менеджером"
          leftText={`Итого: ${priceTransormFormat(data?.forSearch?.motivationSum, true)}`}>
          <SearchMotivationTable
            searchMotivation={data.forSearch}
            userId={data.forSearch?.userId}
            motivationSum={data.forSearch?.motivationSum}
            sxProps={{ maxHeight: "calc(100vh - 170px)" }}
            talbeHeaderColor={talbeHeaderColor}
            month={month}
            year={year}
          />
        </AccordionWrapper>
      )}
    </>
  );
};
