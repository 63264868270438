import { FC, useState } from "react";
import { AsideCardLayout } from "../../../../components/AsideCardLayout/AsideCardLayout";
import { Box, Link, Stack, Typography } from "@mui/material";
import { AddedButton } from "../../../../components/UI/Buttons/AddedButton/AddedButton";
import { VerifiedIcon } from "../ChannelAsideMain/ui/VerifiedByGov/VerifiedIcon/VerifiedIcon";
import { CopyIconButton } from "../../../../components/UI/Buttons/CopyIconButton/CopyIconButton";
import { EditModeButton } from "../../../../components/UI/Buttons/EditModeButton/EditModeButton";
import CreateDocumentField from "../../../ContrActorAsidePage/pages/CreateDocument/ui/CreateDocumentField/CreateDocumentField";
import channelCardStore from "../../../../store/channelCard.store";
import ChannelService from "../../../../services/channel/channel.service";
import { useSearchParams } from "react-router-dom";
import { INTEGRATION_CARD_OPEN } from "../../../../shared/constants/searchParams";
import integrationPageStore from "../../../../store/integrationPage.store";
import integrationTableStore from "../../../../store/integrationTable.store";
import channelTableStore from "../../../../store/channelTable.store";
import { NotificationAlerts } from "../../../../components/NotificationAlerts/NotificationAlerts";
import { IChannelAsideVerified } from "./IChannelAsideVerified";

export const ChannelAsideVerified: FC<IChannelAsideVerified> = (props) => {
  const { channelId, audience, govVerification } = props;

  const { link, number, isVerifiedByGov, update_id } = govVerification;
  const [loading, setLoading] = useState(false);

  const [searchParams] = useSearchParams();

  const [isEditMode, setEditMode] = useState(false);
  const [numberValue, setNumberValue] = useState(() => number);
  const [linkValue, setLinkValue] = useState(() => link);
  const [errorValue, setErrorValue] = useState<string | null>(null);
  const [successValue, setSuccessValue] = useState(false);

  const handleOpenEdit = () => {
    setEditMode(true);
  };

  const handleCloseEdit = () => {
    setEditMode(false);
    setNumberValue(number);
    setLinkValue(link);
  };

  const sumbit = () => {
    setLoading(true);

    const option = {
      channelId,
      link: linkValue?.trim() !== "" && linkValue !== null ? linkValue.trim() : null,
      number: numberValue !== "" ? numberValue : null,
      update_id,
    };

    ChannelService.changeVerificationByGov(option)
      .then((res) => {
        channelCardStore.setGovVerification(res.data);
        channelTableStore.setGovVerification(channelId, res.data);
        if (searchParams.has(INTEGRATION_CARD_OPEN)) {
          integrationPageStore.setIsVerifiedByGov(res.data.isVerifiedByGov);
          integrationTableStore.updateIsVerifiedByGov(channelId, res.data);
        }
        setSuccessValue(true);
        setLinkValue(res.data.link);
        setNumberValue(res.data.number);
      })
      .catch((err) => {
        setErrorValue(err.response.data.message);
      })
      .finally(() => {
        setEditMode(false);
        setLoading(false);
      });
  };

  return (
    <AsideCardLayout
      headerTitle={"Регистрация на госуслугах"}
      headerContent={<VerifiedIcon audience={audience || 0} isVerified={isVerifiedByGov} />}
      sx={{ mt: 2 }}
      showDivider={false}
      rightContent={
        <>
          {(isVerifiedByGov || isEditMode) && (
            <EditModeButton
              isEdit={isEditMode}
              onOpenEdit={handleOpenEdit}
              onClose={handleCloseEdit}
              onSubmit={sumbit}
              loading={loading}
            />
          )}
          {!isVerifiedByGov && !isEditMode && (
            <Box>
              <AddedButton variant="outlined" onClick={handleOpenEdit}>
                Добавить
              </AddedButton>
            </Box>
          )}
        </>
      }>
      <Box pt={1}>
        {isEditMode ? (
          <Stack spacing={1}>
            <CreateDocumentField
              name="number"
              title="Номер регистрации"
              onChange={(e) => setNumberValue(e.target.value)}
              titleWidth="145px"
              value={numberValue}
              type="number"
            />
            <CreateDocumentField
              name="link"
              title="Ссылка регистрации"
              onChange={(e) => setLinkValue(e.target.value)}
              titleWidth="145px"
              value={linkValue}
            />
          </Stack>
        ) : (
          <Stack>
            {numberValue && (
              <Stack direction={"row"} spacing={0.5} alignItems={"center"}>
                <Typography flexShrink={0}>Номер регистрации:</Typography>
                <Typography overflow={"hidden"} textOverflow={"ellipsis"} whiteSpace={"nowrap"}>
                  {numberValue}
                </Typography>
                <CopyIconButton copyText={numberValue} />
              </Stack>
            )}
            {linkValue && (
              <Stack direction={"row"} spacing={0.5} alignItems={"center"}>
                <Typography flexShrink={0}>Ссылка регистрации:</Typography>
                <Link
                  color={"secondary"}
                  href={linkValue}
                  target="_blank"
                  sx={{ textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden" }}>
                  {linkValue}
                </Link>
                <CopyIconButton copyText={linkValue} />
              </Stack>
            )}
          </Stack>
        )}
      </Box>
      <NotificationAlerts
        sucsess={{
          text: "Изменения успешно сохранились!",
          open: successValue,
          onClose: () => setSuccessValue(false),
        }}
        error={{
          text: errorValue ? errorValue : "",
          open: errorValue ? true : false,
          onClose: () => setErrorValue(null),
        }}
      />
    </AsideCardLayout>
  );
};
