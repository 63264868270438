import { IStrategyField } from "../../../../../strategy/interface/IStrategyField";

export const getTransferredChannelsItems = (): IStrategyField[] => {
  return [
    { title: "ID", code: "id", isSimple: true, immutable: false },
    {
      title: "Имя канала ",
      code: "name",
      immutable: false,
      isSimple: false,
      isChannel: true,
      maxWidth: "200px",
      showArrowLink: true,
    },
    { title: "Статус", code: "status", immutable: false, isSimple: false, isStatuses: true },
    { title: "Тематика", code: "theme", isSimple: true, immutable: false, maxWidth: "150px" },
    {
      title: "Подписчики",
      code: "audience",
      immutable: false,
      isSimple: false,
      isChangeNum: true,
      isSort: false,
      minWidth: "100px",
      isPercent: false,
      isAudience: true,
      readOnly: true,
    },
    {
      title: "Размер канала",
      code: "size",
      immutable: false,
      isSimple: false,
      isChannelSize: true,
    },
    {
      title: "Просмотры",
      code: "viewsPredict",
      immutable: false,
      isSimple: false,
      isSimpleView: true,
    },
    { title: "Прайс", code: "initialPrice", immutable: false, isSimple: true, isMoneySimple: true },
    {
      title: "Найден",
      code: "dateFound",
      isSimple: false,
      isDate: true,
      isDateEdit: true,
      isFullYears: true,
    },
    {
      title: "Передан",
      code: "dateGiven",
      isSimple: false,
      isDate: true,
      isDateEdit: true,
      isFullYears: true,
    },
    {
      title: "Выпускающий менеджер",
      code: "releaseBy",
      immutable: false,
      isSimple: false,
      isRelease: true,
      isDeleteIcon: false,
      placeholder: "Выберите менеджера",
    },
    {
      title: "Менеджер поиска",
      code: "foundBy",
      isSimple: false,
      isReleaseUsers: true,
      isMenu: false,
    },
    {
      title: "Комментарий",
      code: "posted_comments",
      isSimple: false,
      immutable: false,
      isLastComments: true,
      maxWidth: "130px",
    },
  ];
};

export const getTransferredChannelsAccordionItems = (): IStrategyField[] => {
  return [
    { title: "ID", code: "id", isSimple: true, immutable: false },
    {
      title: "Имя канала ",
      code: "name",
      immutable: false,
      isSimple: false,
      isChannel: true,
      maxWidth: "200px",
      showArrowLink: true,
    },
    { title: "Статус", code: "status", immutable: false, isSimple: false, isStatuses: true },
    { title: "Тематика", code: "theme", isSimple: true, immutable: false, maxWidth: "150px" },
    {
      title: "Подписчики",
      code: "audience",
      immutable: false,
      isSimple: false,
      isChangeNum: true,
      isSort: false,
      minWidth: "100px",
      isPercent: false,
      isAudience: true,
      readOnly: true,
    },
    {
      title: "Размер канала",
      code: "size",
      immutable: false,
      isSimple: false,
      isChannelSize: true,
    },
    {
      title: "Просмотры",
      code: "viewsPredict",
      immutable: false,
      isSimple: false,
      isSimpleView: true,
    },
    { title: "Прайс", code: "initialPrice", immutable: false, isSimple: true, isMoneySimple: true },
    {
      title: "Найден",
      code: "dateFound",
      isSimple: false,
      isDate: true,
      isDateEdit: false,
      isFullYears: true,
    },
    {
      title: "Передан",
      code: "dateGiven",
      isSimple: false,
      isDate: true,
      isDateEdit: false,
      isFullYears: true,
    },
    {
      title: "Выпускающий менеджер",
      code: "releaseBy",
      immutable: false,
      isSimple: false,
      isRelease: true,
      isDeleteIcon: false,
      placeholder: "Выберите менеджера",
    },
    {
      title: "Менеджер поиска",
      code: "foundBy",
      isSimple: false,
      isReleaseUsers: true,
      isMenu: false,
    },
    {
      title: "Комментарий",
      code: "posted_comments",
      isSimple: false,
      immutable: false,
      isLastComments: true,
      maxWidth: "130px",
    },
  ];
};
