import { useState, useEffect } from "react";
import { AsideCardLayout } from "../../../../components/AsideCardLayout/AsideCardLayout";
import MotivationService from "../../../../services/motivation/motivation.service";
import {
  IMotivationCoefsRequest,
  IMotivationReleaseRequest,
  IMotivationSearchPricesRequest,
  IMotivationSearchRequest,
} from "../../../../services/motivation/IMotivationRequest";
import { Box, CircularProgress, Skeleton, Stack, Typography } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import dashboardStore from "../../../../store/dashboard.store";
import { AxiosError } from "axios";
import { FilterMotivation } from "./ui/FilterMotivation/FilterMotivation";
import { MotivationRoiTable } from "./ui/MotivationRoiTable/MotivationRoiTable";
import { InfoMotivationModal } from "./ui/InfoMotivationModal/InfoMotivationModal";
import { SearchChannelPriceTable } from "./ui/SearchChannelPriceTable/SearchChannelPriceTable";
import { AccordionWrapper } from "../../../../components/AccordionWrapper/AccordionWrapper";
import { MotivationAllTables } from "./ui/MotivationAllTables/MotivationAllTables";
import { MotivationKpiPercentChannel } from "./ui/MotivationKpiPercentChannel/MotivationKpiPercentChannel";
import userStore from "../../../../store/user.store";
import { ALL_ROLES, ALL_RULES } from "../../../../shared/rules/interface/EnumRights";
import dayjs from "dayjs";
import isToday from "dayjs/plugin/isToday";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import { GuaranteeTable } from "./ui/GuaranteeTable/GuaranteeTable";
dayjs.extend(isToday);
dayjs.extend(isSameOrAfter);

export const MotivationPage = observer(() => {
  const [searchParams] = useSearchParams();

  const [showContent, setShowContent] = useState<1 | 2>(
    searchParams.get("managerType") === "release"
      ? 1
      : searchParams.get("managerType") === "search"
      ? 2
      : userStore.user?.role === ALL_ROLES.PRE_HEAD_MANAGER
      ? 1
      : userStore.user?.role === ALL_ROLES.SEARCH_HEAD_MANAGER
      ? 2
      : 1
  );

  const [managerId, setManagerId] = useState<null | string>(searchParams.get("manager") || null);

  const [isLoading, setLoading] = useState(false);
  const [isLoadingSearch, setLoadingSearch] = useState(false);
  const [isLoadingByUser, setLoadingByUser] = useState(false);
  const [isLoadingPercentForSales, setLoadingPercentForSales] = useState(false);

  const [isShowFirstTable, setShowFirstTable] = useState(
    searchParams.get("manager") ? false : true
  );

  const currentDashboardDate =
    dashboardStore?.dateValue &&
    dayjs()
      .set("year", dashboardStore.dateValue?.year)
      .set("month", dashboardStore.dateValue?.month - 1);

  const isReadOnlyDate =
    dayjs(currentDashboardDate).isToday() || dayjs(currentDashboardDate).isSameOrAfter(dayjs());

  const getMotivationCoef = (option?: IMotivationCoefsRequest) => {
    setLoading(true);
    setShowContent(1);
    setShowFirstTable(true);

    if (managerId === "8") {
      getPercentForSales(
        {
          month: option?.month,
          userId: option?.userId,
          year: option?.year,
        } || undefined
      );
    }

    dashboardStore.setReleaseMotivationByUser(null);

    MotivationService.getNewMotivationCoefs(option)
      .then((res) => {
        if (option) {
          setManagerId(option.userId?.toString());
        } else {
          setManagerId(null);
        }
        setShowFirstTable(true);
        dashboardStore.setReleaseMotivation(res.data.roiRows);
        dashboardStore.setSizeAudience(
          res.data.sizeAudience !== null && res.data.sizeAudience?.length > 0
            ? res.data.sizeAudience
            : null
        );
        setLoading(false);
      })
      .catch((err) => {
        if (err instanceof AxiosError) {
          setLoading(false);
        }
      });
  };

  const getSearchMotivation = (options: IMotivationSearchRequest) => {
    setLoading(true);
    setShowContent(2);
    setShowFirstTable(true);

    getPercentForSales(
      {
        month: options.month,
        userId: options.userId,
        year: options.year,
      } || undefined
    );

    const option: IMotivationSearchRequest = {
      month: options.month,
      userId: options.userId,
      year: options.year,
    };
    MotivationService.motivationCalcAll(option)
      .then((res) => {
        dashboardStore.setDateValue({ year: option.year, month: option.month });
        if (option) {
          setManagerId(option.userId?.toString());
        } else {
          setManagerId(null);
        }

        dashboardStore.setSearchMotivation(res.data);
        setLoading(false);
      })
      .catch((err) => {
        if (err instanceof AxiosError) {
          setLoading(false);
        }
      });
  };

  const submitReleaseMotivationByUser = (options: IMotivationReleaseRequest) => {
    getReleaseMotivationByUser(options);

    if (searchParams.get("manager") === "8") {
      getAllSearch(true);

      getPercentForSales(
        {
          month: options.month,
          userId: options.userId,
          year: options.year,
        } || undefined
      );
    }
  };

  const getReleaseMotivationByUser = (options: IMotivationReleaseRequest) => {
    setLoadingByUser(true);
    setShowContent(1);
    setShowFirstTable(false);

    const option: IMotivationReleaseRequest = {
      month: options.month,
      userId: options.userId,
      year: options.year,
    };
    MotivationService.motivationCalcAll(option)
      .then((res) => {
        if (option) {
          setManagerId(option.userId?.toString());
        } else {
          setManagerId(null);
        }
        dashboardStore.setDateValue({ year: option.year, month: option.month });
        dashboardStore.setReleaseMotivationByUser(res.data);
        setLoadingByUser(false);
      })
      .catch((err) => {
        if (err instanceof AxiosError) {
          setLoadingByUser(false);
        }
      });
  };

  const getAllSearch = (enadleShowContent?: boolean) => {
    setLoadingSearch(true);

    if (enadleShowContent) {
    } else {
      setShowContent(2);
      getPercentForSales();
    }

    setShowFirstTable(searchParams.get("manager") ? false : true);

    dashboardStore.setSearchMotivation(null);

    MotivationService.searchPrices({
      month: +searchParams.get("date")!?.split("-")?.[1],
      year: +searchParams.get("date")!?.split("-")?.[0],
    })
      .then((res) => {
        dashboardStore.setAllSearchMotivation(res.data);
        setLoadingSearch(false);
      })
      .catch((err) => {
        if (err instanceof AxiosError) {
          setLoadingSearch(false);
        }
      });
  };

  const getPercentForSales = (params?: IMotivationSearchPricesRequest) => {
    setLoadingPercentForSales(true);
    dashboardStore.setPercentForSales(null);

    MotivationService.percentForSales(params)
      .then((res) => {
        dashboardStore.setPercentForSales(res.data);
        setLoadingPercentForSales(false);
      })
      .catch((err) => {
        if (err instanceof AxiosError) {
          setLoadingPercentForSales(false);
        }
      });
  };

  const sortReleaseHandler = (type: string, sortType: "asc" | "desc") =>
    dashboardStore.sortReleaseMotivationByUserTableItem(type, sortType);
  const sortSearchHandler = (type: string, sortType: "asc" | "desc") =>
    dashboardStore.sortSearchMotivationByUserTableItem(type, sortType);

  const getAllGuaranteeNotCompleted = () => {
    MotivationService.getAllGuaranteeNotCompleted().then((res) =>
      dashboardStore.setGuaranteeNotCompleted(res.data)
    );
  };

  useEffect(() => {
    if (showContent === 1) {
      if (
        +searchParams.get("date")!?.split("-")?.[1] &&
        +searchParams.get("date")!?.split("-")?.[0] &&
        +searchParams.get("manager")!
      ) {
        getMotivationCoef({
          month: +searchParams.get("date")!?.split("-")?.[1],
          year: +searchParams.get("date")!?.split("-")?.[0],
          userId: +searchParams.get("manager")!,
        });

        getReleaseMotivationByUser({
          month: +searchParams.get("date")!?.split("-")?.[1],
          year: +searchParams.get("date")!?.split("-")?.[0],
          userId: +searchParams.get("manager")!,
        });
      } else {
        getMotivationCoef();
      }

      if (managerId === "8") {
        getAllSearch(true);

        getPercentForSales(
          {
            month: +searchParams.get("date")!?.split("-")?.[1],
            year: +searchParams.get("date")!?.split("-")?.[0],
            userId: +searchParams.get("manager")!,
          } || undefined
        );
      }

      if (!dashboardStore.releaseMotivationByUser) {
        getAllGuaranteeNotCompleted();
      }
    } else if (showContent === 2) {
      if (searchParams.has("date") && searchParams.has("manager")) {
        getSearchMotivation({
          month: +searchParams.get("date")!?.split("-")?.[1],
          year: +searchParams.get("date")!?.split("-")?.[0],
          userId: +searchParams.get("manager")!,
        });
      }

      getPercentForSales(
        {
          month: +searchParams.get("date")!?.split("-")?.[1],
          year: +searchParams.get("date")!?.split("-")?.[0],
          userId: +searchParams.get("manager")!,
        } || undefined
      );

      getAllSearch();
    }
  }, []);

  return (
    <>
      <AsideCardLayout fullHeight sx={{ p: { sm: 2, xs: 1 }, overflow: "auto", height: "auto" }}>
        <Box
          sx={{
            height: { xs: "auto", md: "inherit" },
            display: "block",
            gridTemplateRows: "min-content 1fr",
            maxHeight: { xs: "auto", sm: "-webkit-fill-available" },
            overflow: "auto",
          }}>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: { lg: "1fr 3fr", md: "1fr 2fr", xs: "1fr" },
              columnGap: 4,
              rowGap: 1,
              gridTemplateRows: "min-content",
              height: { xs: "auto", sm: "inherit" },
              justifyContent: "space-between",
            }}>
            <Box sx={{ gridRow: { xs: 1, md: "auto" } }}>
              <FilterMotivation
                loading={isLoading}
                onSubmitRelease={getMotivationCoef}
                onSubmitSearch={getSearchMotivation}
                onSubmitReleaseByUser={submitReleaseMotivationByUser}
                onSubmitAllSearch={getAllSearch}
                disabledTypeManager={
                  userStore?.user?.rights?.find((x) => x.code === ALL_RULES.search_head)
                    ? true
                    : false
                }
              />
            </Box>
            {showContent === 1 && (
              <Box sx={{ display: "flex", flexDirection: "column", overflow: "auto" }}>
                {isLoading && (
                  <Skeleton variant="rounded" sx={{ width: "100%", height: "300px" }} />
                )}
                {!isLoading && dashboardStore.releaseMotivation && (
                  <MotivationRoiTable
                    readOnly={!isReadOnlyDate}
                    isInitShowTable={false}
                    tableItems={dashboardStore.releaseMotivation}
                    talbeHeaderColor="#f2f3f5"
                    showDeleteIcon={managerId === "0"}
                    showAddedSelect={managerId === "0"}
                    month={+searchParams.get("date")!?.split("-")?.[1] || new Date().getMonth() + 1}
                    userId={managerId !== null ? +managerId : 0}
                    year={+searchParams.get("date")!?.split("-")?.[0] || new Date().getFullYear()}
                    sizeAudience={dashboardStore.sizeAudience}
                  />
                )}
                {!isLoading &&
                  !dashboardStore.releaseMotivationByUser?.forRelease &&
                  dashboardStore.guaranteeNotCompleted &&
                  (managerId === "0" || managerId === null) && (
                    <GuaranteeTable
                      guaranteeNotCompleted={dashboardStore.guaranteeNotCompleted}
                      talbeHeaderColor="#f2f3f5"
                    />
                  )}
                {isLoadingPercentForSales && managerId === "8" && (
                  <Skeleton variant="rounded" sx={{ height: 180, mb: 2 }} />
                )}
                <>
                  {isLoadingByUser && (
                    <Stack spacing={2} sx={{ mt: 2 }}>
                      <Stack direction={"row"} alignItems={"center"} spacing={1}>
                        <Typography textAlign={"center"} variant="h6" fontWeight={"normal"}>
                          Идёт загрузка индивидуальных коэффициентов...
                        </Typography>
                        <CircularProgress sx={{ maxHeight: 30, maxWidth: 30 }} />
                      </Stack>
                      <Skeleton variant="rounded" sx={{ width: "100%", height: "300px" }} />
                    </Stack>
                  )}
                  {!isLoadingByUser && dashboardStore.releaseMotivationByUser && managerId && (
                    <Box
                      sx={{
                        mt: { xs: 1, md: 2 },
                        display: "flex",
                        flexDirection: "column",
                        overflow: "auto",
                        height: "auto",
                      }}>
                      <MotivationAllTables
                        data={dashboardStore.releaseMotivationByUser}
                        sortHandler={sortReleaseHandler}
                        userId={+managerId}
                        isShowChannelMotivation={false}
                        talbeHeaderColor="#f2f3f5"
                        motivationType={(searchParams.get("managerType") as any) || "release"}
                        managerId={+managerId}
                        showDeleteIconRoiTable
                        showAddedSelectRoiTable
                        roiAddedSelectMonth={
                          +searchParams.get("date")!?.split("-")?.[1] || new Date().getMonth() + 1
                        }
                        roiAddedSelectUserId={+managerId || 0}
                        roiAddedSelectYear={
                          +searchParams.get("date")!?.split("-")?.[0] || new Date().getFullYear()
                        }
                        onChangeMotivationByUserCell={dashboardStore.onChangeReleaseMotivationCell}
                        month={
                          +searchParams.get("date")!?.split("-")?.[1] || new Date().getMonth() + 1
                        }
                        year={
                          +searchParams.get("date")!?.split("-")?.[0] || new Date().getFullYear()
                        }
                        sizeAudience={
                          dashboardStore.sizeAudience !== null &&
                          dashboardStore.sizeAudience?.length > 0
                            ? dashboardStore.sizeAudience
                            : null
                        }
                      />
                    </Box>
                  )}
                </>
              </Box>
            )}
            {showContent === 2 && (
              <Box sx={{ display: "flex", flexDirection: "column", overflow: "auto" }}>
                {isLoadingSearch && <Skeleton variant="rounded" sx={{ height: 180, mb: 2 }} />}
                {!isLoadingSearch && dashboardStore.allSearchMotivation && (
                  <AccordionWrapper
                    title="Показатели для расчета KPI за найденные каналы"
                    isOpen={isShowFirstTable}>
                    <SearchChannelPriceTable
                      readOnly={!isReadOnlyDate || managerId !== "0"}
                      data={dashboardStore.allSearchMotivation}
                      talbeHeaderColor="#f2f3f5"
                    />
                  </AccordionWrapper>
                )}
                {isLoadingPercentForSales && (
                  <Skeleton variant="rounded" sx={{ height: 180, mb: 2 }} />
                )}
                {!isLoadingPercentForSales && dashboardStore.percentForSales && (
                  <MotivationKpiPercentChannel
                    isInitOpen
                    readOnly={!isReadOnlyDate || managerId !== "0"}
                    data={dashboardStore.percentForSales}
                  />
                )}
                <>
                  {isLoading && (
                    <Stack alignItems={"center"} spacing={1}>
                      <Stack direction={"row"} alignItems={"center"} spacing={1}>
                        <Typography variant="h5">Идёт расчёт мотивации...</Typography>
                        <CircularProgress
                          sx={{ minHeight: 35, minWidth: 35, maxHeight: 35, maxWidth: 35 }}
                        />
                      </Stack>
                      <Typography variant="h5">Пожалуйста подождите</Typography>
                    </Stack>
                  )}
                  {!isLoading && dashboardStore.searchMotivation && managerId ? (
                    <Box
                      sx={{
                        mt: { xs: 1, md: 2 },
                        display: "flex",
                        flexDirection: "column",
                        overflow: "auto",
                        height: "auto",
                      }}>
                      <MotivationAllTables
                        data={dashboardStore.searchMotivation}
                        sortHandler={sortSearchHandler}
                        isShowChannelMotivation
                        userId={+managerId}
                        talbeHeaderColor="#f2f3f5"
                        motivationType={(searchParams.get("managerType") as any) || "release"}
                        managerId={+managerId}
                        showDeleteIconRoiTable={false}
                        showAddedSelectRoiTable={false}
                        onChangeMotivationByUserCell={dashboardStore.onChangeSearchMotivationCell}
                        month={
                          +searchParams.get("date")!?.split("-")?.[1] || new Date().getMonth() + 1
                        }
                        year={
                          +searchParams.get("date")!?.split("-")?.[0] || new Date().getFullYear()
                        }
                        sizeAudience={null}
                      />
                    </Box>
                  ) : null}
                </>
              </Box>
            )}
          </Box>
        </Box>
      </AsideCardLayout>
      <InfoMotivationModal />
    </>
  );
});
