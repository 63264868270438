import { tableModels } from "../models/tableModels";
import { ITableModel } from "../models/IMotivationTableSection";
import { IMotivationCalcAll } from "../../../services/motivation/IMotivationResponse";
import { ALL_ROLES } from "../../../shared/rules/interface/EnumRights";
import { priceTransormFormat } from "../../../shared/functions/functions";

export const initDataForTable = (
  type: ALL_ROLES,
  summaryTableData: IMotivationCalcAll | null
): ITableModel => {
  tableModels.rows = [];
  if (summaryTableData !== null) {
    tableModels.rows.push({
      name: "KPI за выпущенные интеграции",
      value: summaryTableData?.forRelease?.motivationSumm?.toString() || "0",
      type: "motivation",
    });

    if (type === ALL_ROLES.SEARCH_MANAGER)
      tableModels.rows.push({
        name: "KPI за найденные каналы (новые)",
        value: summaryTableData?.summaryTable?.forSearch?.toString(),
        type: "motivation",
      });

    tableModels.rows.push({
      name: "Перерасчет KPI за предыдущий месяц за выпущенные интеграции",
      value: summaryTableData?.summaryTable?.diffFromPrevMonth?.toString(),
      type: "motivation",
      isAccordion: true,
      lastTextAccordion:
        summaryTableData?.summaryTable?.diffFromPrevMonth < 0
          ? `0 ₽ (${priceTransormFormat(summaryTableData?.summaryTable?.diffFromPrevMonth, true)})`
          : `${priceTransormFormat(summaryTableData?.summaryTable?.diffFromPrevMonth, true)}`,
      subContent: [
        {
          name: "KPI за выпущенные интеграции набегающий",
          value: summaryTableData?.summaryTable?.fixedSummInPrevMonthRecalc?.toString(),
          type: "motivation",
        },
        {
          name: "KPI за выпущенные интеграции зафиксированный",
          value: summaryTableData?.summaryTable?.fixedSummInPrevMonth?.toString(),
          type: "motivation",
        },
      ],
    });

    tableModels.rows.push({
      name: "ИТОГО KPI",
      type: "motivation",
      value: summaryTableData?.summaryTable?.kpiSumm?.toString(),
      fontWeight: "bold",
    });

    tableModels.rows.push({
      name: "Штрафы",
      value: summaryTableData?.summaryTable?.finesSumm?.toString(),
      isEdit: true,
      type: "fine",
    });

    tableModels.rows.push({
      name: "Фикс",
      value: summaryTableData?.summaryTable?.salary?.toString(),
      isEdit: true,
      type: "salary",
    });

    if (type === ALL_ROLES.SEARCH_HEAD_MANAGER || type === ALL_ROLES.PRE_HEAD_MANAGER)
      tableModels.rows.push({
        name: "За сотрудников",
        value: summaryTableData?.summaryTable?.forManagers?.toString(),
        isEdit: true,
        type: "motivation",
      });

    tableModels.rows.push({
      name: "ИТОГО",
      value: summaryTableData?.summaryTable?.totalSumm?.toString(),
      fontWeight: "bold",
      type: "motivation",
    });
  }
  return tableModels;
};
