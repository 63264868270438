import { useState } from "react";
import { Box, Stack, Tooltip } from "@mui/material";
import { AlertModal } from "../../../AlertModal/AlertModal";
import { IControlButtonPanel } from "./IControlButtonPanel";
import { IBurgerItem } from "../../models/ITableItem";
import { CustomIconButton } from "../../../UI/Buttons/CustomIconButton/CustomIconButton";

export const ControlButtonPanel = (props: IControlButtonPanel) => {
  const { lists, isBanned, restProps } = props;

  const [showAlertProtected, setShowAlertProtected] = useState(false);

  const filteredItems = lists.filter((item) => {
    const isBannedCheck = isBanned
      ? item.title !== "Копировать" && item.title !== "Создать интеграцию"
      : true;
    const isCloneCheck = restProps?.isClone && item.title === "Копировать" ? false : true;

    return isBannedCheck && isCloneCheck;
  });

  const handleItemClick = (item: IBurgerItem) => {
    if (!item.alertProtacted) {
      item.onNavigate?.(restProps["id"]);
    } else {
      setShowAlertProtected(true);
    }
  };

  const handleSubmit = (item: IBurgerItem) => {
    setShowAlertProtected(false);
    item.onClick?.(restProps["id"]);
  };

  return (
    <Box display="flex">
      {filteredItems.map((item) => (
        <Box key={item.title}>
          {item.isShow && (
            <Tooltip title={item.title}>
              <Stack direction="row">
                <CustomIconButton
                  size={item.sizeIcon || "medium"}
                  onClick={() => handleItemClick(item)}
                  sx={{ padding: "6px" }}>
                  {item.icon}
                </CustomIconButton>
              </Stack>
            </Tooltip>
          )}
          {showAlertProtected && item.alertProtectedSetting && (
            <AlertModal
              title={item.alertProtectedSetting.title}
              subtext={item.alertProtectedSetting.subtext}
              open={showAlertProtected}
              onClose={() => setShowAlertProtected(false)}
              onSubmit={() => handleSubmit(item)}
              buttonSubmitName={item.alertProtectedSetting.buttonSubmitName}
              buttonCancelName={item.alertProtectedSetting.buttonCancelName}
              colorSubmit={item.alertProtectedSetting.colorSubmit}
            />
          )}
        </Box>
      ))}
    </Box>
  );
};
