import { Stack, Typography } from "@mui/material";
import { FC } from "react";
import { CertificateIcon } from "../../../../ContrActorTable/ui/ContrActorCreateModals/ui/CertificateIcon/CertificateIcon";
import { IShortName } from "./IShortName";

export const ShortName: FC<IShortName> = (props) => {
  const { shortName, selfEmployedDetails } = props;

  return (
    <Stack direction={"row"} alignItems={"center"}>
      {selfEmployedDetails && selfEmployedDetails?.hasNepCertificate && (
        <CertificateIcon
          hasCertificate={selfEmployedDetails.hasNepCertificate}
          hasCertificateFrom={selfEmployedDetails.hasNepCertificateFrom}
          showTooltip
        />
      )}
      <Typography noWrap>{shortName}</Typography>
    </Stack>
  );
};
