import { FC, useState } from "react";
import { IMotivationReleaseTable } from "./IMotivationReleaseTable";
import { TableDefault } from "../../../../../../components/Table/TableDefault";
import { TableItem } from "../../../../../../components/Table/ui/TableItem";
import { IStrategyField } from "../../../../../../strategy/interface/IStrategyField";
import { AccordionWrapper } from "../../../../../../components/AccordionWrapper/AccordionWrapper";
import { Stack, Typography } from "@mui/material";
import { priceTransormFormat } from "../../../../../../shared/functions/functions";
import { observer } from "mobx-react-lite";
import { IUser } from "../../../../../../entities/user/models/IUser";
import AllUsersStore from "../../../../../../store/AllUsers.store";
import { ALL_ROLES } from "../../../../../../shared/rules/interface/EnumRights";

export const MotivationReleaseTable: FC<IMotivationReleaseTable> = observer((props) => {
  const {
    motivationReleases,
    sxProps,
    motivationSumm,
    isInitShowTable = false,
    talbeHeaderColor,
    motivationType,
    managerId,
    sortHandler,
    hideUrlSortCode,
  } = props;

  const currentUser: undefined | IUser = AllUsersStore?.users?.find((x) => x?.id === managerId);

  const [currentSortType, setCurrentSortType] = useState("");

  const talbeRow: IStrategyField[] = [
    { title: "ID", code: "id", immutable: false, isSimple: true },
    {
      title: "Дата выпуска",
      code: "integration_date",
      immutable: false,
      isSimple: false,
      isDate: true,
      isSort: true,
    },
    {
      title: "Имя канала",
      code: "channel",
      immutable: false,
      isSimple: false,
      isChannelType: true,
      maxWidth: "200px",
      isShowTypeText: true,
    },
    { title: "Промокод", code: "promokod", immutable: false, isSimple: true },
    { title: "Заказы", code: "validOrders", immutable: false, isSimple: false, isSimpleView: true },
    {
      title: "Стоимость",
      code: "price",
      immutable: false,
      isSimple: false,
      isIntegrationPaidSumm: true,
    },
    {
      title: "Выручка",
      code: "ordersSumm",
      immutable: false,
      isSimple: false,
      isIntegrationPaidSumm: true,
    },
    { title: "ROI", code: "roi", immutable: false, isSimple: true, isPercent: true },
    {
      title: "Старый / Новый",
      code: "firstIntegrationWithChannel",
      immutable: false,
      isSimple: false,
      isFirstIntegrationWithChannel: true,
      maxWidth: "65px",
    },
    { title: "Кем найден", code: "userId", immutable: false, isSimple: false, isManagerUser: true },
    { title: "Коэфф", code: "coef", immutable: false, isSimple: true, maxWidth: "100px" },
    {
      title: "Итого",
      code: "totalPrice",
      immutable: false,
      isSimple: false,
      isIntegrationPaidSumm: true,
    },
  ];

  return (
    <AccordionWrapper
      title="Детализация KPI за выпущенные интеграции"
      leftText={motivationSumm ? `KPI: ${priceTransormFormat(motivationSumm, true)}` : undefined}
      isOpen={isInitShowTable}>
      <TableDefault
        tableRow={
          currentUser?.role === ALL_ROLES.PRE_HEAD_MANAGER ||
          currentUser?.role === ALL_ROLES.RELEASE_MANAGER ||
          currentUser?.role === ALL_ROLES.SEARCH_HEAD_MANAGER
            ? talbeRow
            : [...talbeRow].filter((x) => x.code !== "coef")
        }
        showBorderRadius={false}
        maxWidth="100%"
        sx={sxProps}
        talbeHeaderColor={talbeHeaderColor}
        isShowSort
        onChangeCurrentSortType={setCurrentSortType}
        currentSortType={currentSortType}
        sortHandler={sortHandler}
        hideUrlSortCode={hideUrlSortCode}>
        {motivationReleases.map((item) => {
          const props = {
            ...item.integration,
            coef: item.coef,
            totalPrice:
              currentUser?.role === ALL_ROLES.PRE_HEAD_MANAGER ||
              currentUser?.role === ALL_ROLES.RELEASE_MANAGER ||
              currentUser?.role === ALL_ROLES.SEARCH_HEAD_MANAGER
                ? item.releaseMotivationPrice
                : item.searchMotivationPrice,
            channel: item.integration?.channel,
            channelType: item.integration?.channel?.type?.name!,
            promokod: item.integration?.promokod?.code,
            userId: item.integration?.channel?.foundById,
          };
          return (
            <TableItem
              key={item.integration.id}
              cells={
                currentUser?.role === ALL_ROLES.PRE_HEAD_MANAGER ||
                currentUser?.role === ALL_ROLES.RELEASE_MANAGER ||
                currentUser?.role === ALL_ROLES.SEARCH_HEAD_MANAGER
                  ? talbeRow
                  : [...talbeRow].filter((x) => x.code !== "coef")
              }
              hover
              {...props}
              sx={{ p: 1, pt: 1, pb: 1 }}
            />
          );
        })}
      </TableDefault>
      <Stack sx={{ mt: 2 }} spacing={0.5} direction={"row"} alignItems={"center"} flexWrap={"wrap"}>
        <Typography sx={{ fontSize: { xs: "16px", md: "1.28rem" } }} fontWeight={"normal"}>
          Мотивация менеджера составила: {motivationSumm !== null ? null : "данных не найдено"}
        </Typography>
        {motivationSumm !== null ? (
          <Typography
            variant="h5"
            sx={{ fontWeight: "bold", fontSize: { xs: "16px", md: "1.28rem" } }}>
            {priceTransormFormat(motivationSumm)}
          </Typography>
        ) : null}
      </Stack>
    </AccordionWrapper>
  );
});
