import { WorkspacePremium } from "@mui/icons-material";
import { Icon, Stack, Tooltip, Typography } from "@mui/material";
import { FC, useMemo } from "react";
import { ICertificateIcon } from "./ICertificateIcon";
import dayjs from "dayjs";

export const CertificateIcon: FC<ICertificateIcon> = (props) => {
  const {
    size = "large",
    hasCertificate,
    hasCertificateFrom,
    showTooltip = false,
    showDates = false,
  } = props;

  const dateTo = useMemo(() => {
    return dayjs(hasCertificateFrom, "DD.MM.YYYY").add(1, "year");
  }, [hasCertificateFrom]);
  const currentDate = dayjs();
  const isDateValid = dateTo.isAfter(currentDate);

  const dates = <Typography>{`${hasCertificateFrom} - ${dateTo.format("DD.MM.YYYY")}`}</Typography>;

  const iconColor = () => {
    if (!hasCertificate) {
      return "primary";
    }

    if (!isDateValid) {
      return "#ff5252";
    }

    return "#78e379";
  };

  return (
    <Tooltip title={showTooltip ? dates : null}>
      <Stack direction={"row"} alignItems={"center"}>
        <Icon fontSize={size}>
          <WorkspacePremium fontSize={size} htmlColor={iconColor()} />
        </Icon>
        {showDates && dates}
      </Stack>
    </Tooltip>
  );
};
