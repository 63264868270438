import { OpenInNew } from "@mui/icons-material";
import { FC, useCallback, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { IBurgerItem } from "../../../../../../components/Table/models/ITableItem";
import { TableDefault } from "../../../../../../components/Table/TableDefault";
import { TableItem } from "../../../../../../components/Table/ui/TableItem";
import { INTEGRATION_CARD_OPEN } from "../../../../../../shared/constants/searchParams";
import statusesStore from "../../../../../../store/statuses.store";
import { IStrategyField } from "../../../../../../strategy/interface/IStrategyField";
import { IGuaranteeTable } from "./IGuaranteeTable";
import { AccordionWrapper } from "../../../../../../components/AccordionWrapper/AccordionWrapper";
import { getIntegrationTableItemColor } from "../../../../../../components/IntegrationTable/shared/dataList";

export const GuaranteeTable: FC<IGuaranteeTable> = (props) => {
  const { talbeHeaderColor, guaranteeNotCompleted } = props;

  const [searchParams, setSearchParams] = useSearchParams();

  const navigateIntagration = useCallback((id: string | number) => {
    searchParams.set(INTEGRATION_CARD_OPEN, id.toString());

    setSearchParams(searchParams);
  }, []);

  const [controlButtonPanel] = useState<IBurgerItem[]>([
    {
      title: "Открыть и посмотреть",
      onNavigate: navigateIntagration,
      isShow: true,
      icon: <OpenInNew />,
    },
  ]);

  const talbeRow: IStrategyField[] = [
    {
      title: "Имя канала",
      code: "channel",
      immutable: false,
      isSimple: false,
      isChannelType: true,
      maxWidth: "200px",
      isShowTypeText: true,
    },
    {
      title: "Старый / Новый",
      code: "firstIntegrationWithChannel",
      immutable: false,
      isSimple: false,
      isFirstIntegrationWithChannel: true,
      maxWidth: "65px",
    },
    { code: "status", title: "Статус", immutable: false, isSimple: false, isStatuses: true },
    {
      code: "integration_date",
      title: "Запланированная дата выхода",
      immutable: false,
      isSimple: false,
      isDate: true,
    },
    {
      code: "endDate",
      title: "Дата окончания гаранта / Дата создания перевыпуска",
      immutable: false,
      isSimple: false,
      isDate: true,
    },
    {
      code: "views",
      title: "Просмотры",
      immutable: false,
      isSimple: false,
      isSimpleView: true,
    },
    {
      code: "badBoysViews",
      title: "Необходимо набрать просмотров",
      immutable: false,
      isSimple: false,
      isSimpleView: true,
    },
    {
      title: "Стоимость",
      code: "price",
      immutable: false,
      isSimple: false,
      isIntegrationPaidSumm: true,
    },
    {
      code: "releaseUser",
      title: "Выпускающий менеджер",
      immutable: false,
      isSimple: false,
      isReleaseUsers: true,
    },
  ];

  return (
    <AccordionWrapper title="Перевыпуски по гаранту" isOpen={guaranteeNotCompleted?.length !== 0 ? true : false}>
      <TableDefault
        tableRow={talbeRow}
        showBorderRadius={false}
        maxWidth="100%"
        showBurgerCell
        talbeHeaderColor={talbeHeaderColor}
        sx={{ maxHeight: "calc(100vh - 230px)" }}>
        {guaranteeNotCompleted?.map((item) => {
          const newItem = {
            ...item,
            releaseUser: {
              id: item.releaseUser,
            },
            endDate: item.isClone ? item.guarantee.repeatGuaranteeDate : item.guarantee.lastDate,
          };
          return (
            <TableItem
              controlButtonPanel={controlButtonPanel}
              key={item.id}
              cells={talbeRow}
              statusStore={statusesStore?.statuses}
              isStatusReadOnly
              hover
              backgroundColor={getIntegrationTableItemColor(
                item.guarantee,
                item.views,
                item.integration_date
              )}
              {...newItem}
            />
          );
        })}
      </TableDefault>
    </AccordionWrapper>
  );
};
